<template>
  <div id="index_dw">
    <div class="header_dw">
      地方文献
    </div>
    <div class="content">
      <div class="subt_c" style="padding-right: 0.5rem">
        <p v-if="condition.page!==1" style="border-radius: 0 100px 100px 0;padding-right: 1rem" @click="getPage(1)">
          <i class="iconfont iconjiantou13"></i>
        </p>
      </div>
      <div class="center_c" ref="centerC">
        <div class="bookslst" ref="photoW" v-for="(item,index) in bookList" :key="index"
             @click="getDetail(item)"
             :style="{marginRight:index+1!=0&&(index+1)%4==0?'0':'2%',width:poImgW,height:poImgH,marginBottom:marginBottomIMG}">
          <img :src="item.img">
          <div class="qrcode" :ref="'qrCodeUrl'+item.book_id"></div>
        </div>
      </div>
      <div class="subt_c" style="padding-left: 0.5rem">
        <p v-if="condition.page!=last_page" style="border-radius:100px 0 0 100px;padding-left: 1rem"
           @click="getPage(2)">
          <i class="iconfont iconjiantou14"></i>
        </p>
      </div>
    </div>
    <div class="footer">
      <div class="resert" @click="Refresh">
        <i class="iconfont icon_zhongzhi"></i>
        <p>刷新</p>
      </div>
      <div class="pages">
        <span>{{ condition.page }}</span>/<span>{{ last_page }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2/qrcode'

export default {
  name:'index_dwName',
  data() {
    return {
      bookList: [],
      condition: {
        name: "", //书名
        category_id: "", //书籍分类id
        culture_id: "", //文化分类id
        press_id: "", //出版社id
        library_id: this.$route.query.number ? this.$route.query.number : (JSON.parse(localStorage.getItem("theme_color")).library_id ? JSON.parse(localStorage.getItem("theme_color")).library_id : ''), //馆藏id  48内蒙古财经大学图书馆
        isbn: "", //书号
        author: "", //作者名称
        start_time: "", //出版开始时间
        end_time: "", //出版结束时间
        page: 1,
        per_page: '',
        resourceclass_id: '335',//地方文献

      },
      dataListUrl: "api/web/v1/h5/booklist",
      last_page: '',

      poImgW: '',
      poImgH: '',
      marginBottomIMG: '',
      qrcodeWH: '',
    }
  },
  methods: {
    /*****************************************************************
     *
     * 获取列表数据
     *
     *****************************************************************/
    queryData() {
      const _this = this;

      _this.showToastMask(true);
      _this.$http.get(`${_this.dataListUrl}`, {params: _this.condition,})
          .then((val) => {
            if (val.data.length) {
              setTimeout(function () {
                val.data.forEach((it) => {
                  _this.creatQrCode(_this.condition.library_id, it.book_id,it.language)
                })
              }, 200)

              _this.bookList = val.data
              _this.last_page = val.pages.last_page
              _this.showToastMask(false);
            } else {
              _this.bookList = []
              _this.showToastMask(false);
            }

          }).catch((err) => {
        _this.showToastMask(false);
      });
    },
    /*****************************************************************
     *
     * 上一页，下一页
     *
     *****************************************************************/
    getPage(now) {
      let _this = this
      if (now == 1) {
        _this.bookList.forEach((it) => {
          let gv = 'qrCodeUrl' + it.book_id
          _this.$refs[gv][0].innerHTML = ''
        })
        this.condition.page -= 1
        this.queryData()


      } else if (now == 2) {
        _this.bookList.forEach((it) => {
          let gv = 'qrCodeUrl' + it.book_id
          _this.$refs[gv][0].innerHTML = ''
        })
        this.condition.page += 1
        this.queryData()


      }
    },
    /*****************************************************************
     *
     * 生成二维码
     *
     *****************************************************************/
    creatQrCode(l_id, b_id,lang) {
      let _this = this
      let url
      if(lang=='mn'){
        url = 'https://bookh5.aqitai.com/index_infor_mn?id=' + b_id + '&number=' + l_id
      }else {
       url = 'https://bookh5.aqitai.com/index_infor?id=' + b_id + '&number=' + l_id
      }


      let gv = 'qrCodeUrl' + b_id
      var qrcode = new QRCode(this.$refs[gv][0], {
        text: url, // 需要转换为二维码的内容
        width: _this.qrcodeWH,
        height: _this.qrcodeWH,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H
      })
      // qrcode.clear();
      // qrcode.makeCode(url);
    },
    /*****************************************************************
     *
     * 刷新当前页面
     *
     *****************************************************************/
    Refresh() {
      let _this = this
      _this.bookList.forEach((it) => {
        let gv = 'qrCodeUrl' + it.book_id
        _this.$refs[gv][0].innerHTML = ''
      })
      _this.queryData()
    },
    //计算图片的宽高
    jsWH() {
      let _this = this
      _this.$nextTick(function () {
        var netBox = _this.$refs.centerC
        var width = (netBox.clientWidth - (netBox.clientWidth * 0.06)) / 4
        _this.poImgW = width + 'px'
        _this.poImgH = width / 0.7 + 'px'
        _this.qrcodeWH = width / 1.5
        _this.marginBottomIMG = netBox.clientWidth * 0.02 + 'px'
        var totHeight = netBox.clientHeight
        var number = totHeight / (width / 0.7 + netBox.clientWidth * 0.02)
        _this.condition.per_page = parseInt(number) * 4<=1?4:parseInt(number) * 4
        this.queryData()
      })
    },
    //点击进入详情页
    getDetail(val) {
      if(val.language=='mn'){
        this.$router.push({
          path: '/bookinfor_dw_mn',
          query: {
            id: val.book_id,
            number: this.condition.library_id,
          }
        })
      }else{
        this.$router.push({
          path: '/bookinfor_dw',
          query: {
            id: val.book_id,
            number: this.condition.library_id,
          }
        })
      }

    }
  },
  mounted() {
    this.jsWH()
  }
}
</script>

<style lang="less" scoped>
#index_dw {
  width: 100%;
  height: 100%;
  background: var(--themeColor);

  .header_dw {
    width: 100%;
    height: 65px;
    //text-align: center;
    //line-height: 8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 32px;
    border-bottom: 1px solid #ffffff;
  }

  .footer {
    width: 100%;
    height: 65px;
    border-top: 1px solid #ffffff;
    display: flex;
    justify-content: space-between;

    .resert {
      width: 65px;
      height: 65px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      font-size: 14px;

      i {
        font-size: 25px;
        margin-bottom: 5px;
      }
    }

    .pages {
      width: 65px;
      height: 65px;
      font-size: 30px;
      color: #ffffff;
      text-align: center;
      line-height: 65px;

      span:last-child {
        font-size: 20px;
      }
    }
  }

  .content {
    width: 100%;
    height: calc(100% - 130px);
    display: flex;
    align-items: center;
    padding: 5.58rem 0;

    .subt_c {
      width: 4rem;
      height: 100%;
      display: flex;
      align-items: center;

      p {
        width: 100%;
        height: 6rem;
        background: rgba(255, 255, 255, 0.2);
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        i {
          color: var(--themeColor);
          font-size: 6rem;
        }
      }
    }

    .center_c {
      width: calc(100% - 8rem);
      height: 100%;

      .bookslst {
        //width: calc(calc(100% - 4.5rem) / 4);
        ////width: 12rem;
        //height: width /  0.6;
        margin-right: 1.5rem;
        position: relative;
        float: left;

        img {
          width: 100%;
          height: 100%;
        }

        .qrcode {
          //width: 7rem;
          //height: 7rem;
          position: absolute;
          bottom: 0;
          right: 0;
        }
      }
    }
  }
}
</style>
